<template>
    <section class="temas-curso px-2 pt-4">
        <div class="row mx-0 a-center">
            <div class="col px-0 br-12 bg-progress" style="height:12px;">
                <div class="bg-gr-general h-100 br-12" :style="`width:${avance}%`" />
            </div>
            <div class="col-auto text-gris f-16">
                Avance {{ avance }} %
            </div>
        </div>
        <div v-for="(tema, t) in temasMapped" :key="t" class="row mx-0">
            <div class="col-12 px-0">
                <div class="row mx-0 a-center border mx-0 py-2 my-2 mt-3 cr-pointer bg-whitesmoke  br-12" @click="desplegar(tema)">
                    <div class="col f-18" :class="current_topic == tema.id ? 'text-general f-600' : 'text-gris f-600'">
                        {{ tema.nombre }}
                    </div>
                    <div class="col-2 text-general f-500 f-17 text-general">
                        {{ tema.avance ? tema.avance.avance : 0 }} %
                    </div>
                    <div class="col-2">
                        <p v-if="tema.avance ? tema.avance.calificacion == null: true" @click="calificarTema(tema.primera_interaccion)" style="width: fit-content">
                            <i class="icon-star-outline f-18 text-yellow " /> Calificar
                        </p>
                        <p  class="" v-else>
                            <i class="icon-star f-18 text-yellow " />
                            {{ tema.avance.calificacion }}
                        </p>
                    </div>
                    <div class="col-auto px-1">
                        <i class="cr-pointer" :class="tema.desplegado ? 'icon-down-open' : 'icon-up-open'" />
                    </div>
                </div>
            </div>
            <div v-if="tema.desplegado" class="col-12 content">
                <div v-html="tema.contenido" />
                <div v-for="(recurso, s) in tema.recursos" :key="s" class="row pb-2 px-3 a-center mt-2 mx-0 border-bottom">
                    <div class="col-auto px-0 cr-pointer" @click="setVisualizar(recurso)">
                        <i :class="`${icon_extension(recurso.archivo)} i f-18 text-general`" />
                    </div>
                    <div class="col text-gris f-16 f-600 cr-pointer tres-puntos" @click="setVisualizar(recurso)">
                        {{ recurso.nombre }}
                    </div>
                    <div class="col-auto">
                        <i v-show="recurso.descargable" class="icon-download f-18 cr-pointer text-general" @click="downloadURI(recurso.archivo,recurso.nombre)" />
                    </div>
                    <div class="col-2 f-16">
                        {{ recurso.peso }} MB
                    </div>
                    <div class="col-2 f-16">
                        <p class="text-center tres-puntos" v-text="recurso.primera_interaccion ? recurso.primera_interaccion.fecha : '--/--/----'" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials-->
        <modal-calificar-tema ref="CalificarTema" @mandar-calificacion="calificar" />
        <!-- <visor-pdf ref="vPdf" /> -->
        <modal-video ref="modal_video" />
        <modal-imagen ref="ver_archivo" cerrar />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Curso from "../../../services/cursos/ver_cursos";
export default {
    components: {
        modalCalificarTema: () => import('../partials/modalCalificarTema')
    },
    data(){
        return {
            temas: [],
            current_topic: null,
            loadingRecursos: false,
        }
    },
    computed: {
        ...mapGetters({
            avance: 'cursos/ver_cursos/avance'
        }),
        idCurso(){
            return this.$route.params.id_curso
        },
        temasMapped(){
            return this.temas.map(el => ({...el, desplegado: el.id == this.current_topic}))
        }
    },
    mounted(){
        this.getTemas()
    },
    methods: {
        calificarTema(primera_interaccion){
            let { calificacion, calificacion_comentario, id_tema, created_by } =  primera_interaccion
            let params = {calificacion, calificacion_comentario, id_tema, created_by}
            this.$refs.CalificarTema.toggle(params);
        },
        calificar(item){
            let tema = this.temas.find(t => t.id == item.id_tema)
            tema.avance.calificacion = item.calificacion
        },
        async desplegar(tema){
            try {
                if (tema.puedeDesplegar){
                    this.current_topic = tema.id == this.current_topic ? null : tema.id
                    if (!tema.desplegado){
                        this.crearInteraccion(1,tema.id,tema.primera_interaccion)
                    }
                } else {
                    this.notificacion('Tema','No puede iniciar tema hasta que termines el anterior','error')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async crearInteraccion(tipo, idInteraccion, primeraInteraccion){
            try {
                let params = {
                    tipo,
                    id_interaccion: idInteraccion,
                    id_curso: this.idCurso
                }        
                const { data: { data } } = await Curso.crearInteraccion(params)
                if (!primeraInteraccion){
                    this.getTemas()
                }
                this.$store.dispatch('cursos/ver_cursos/actionObtenerAvanceUsuario', this.idCurso)
            } catch (error){
                this.error_catch(error)
            }
        },
        async getTemas(){
            try {
                this.loadingRecursos = this.$loading({
                    lock: true,
                    text: 'Cargando',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                const { data: { data } } = await Curso.getTemas(this.idCurso)
                this.temas = data.temas
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loadingRecursos.close()
            }
        },
        async setVisualizar(row){
            await this.crearInteraccion(2, row.id, row.primera_interaccion)
            if (this.getExt(row.archivo) == "pdf"){
                window.open(`/visor?nombre=${row.archivo_firmado}`,'_blank')
            }else if(this.getExt(row.archivo) == "mp4"){
                this.$refs.modal_video.ModalVideo(row.archivo_firmado)
            }else if(this.file_type(row.archivo) == "image/*"){
                this.$refs.ver_archivo.toggle(row.archivo_firmado)
            }else{
                this.downloadURI(row.archivo, row.nombre);  
            }
        },        
        getExt(archivo){
            let ext = archivo.split('.').pop()
            return ext
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-progress{
    background-color: #F8F8F8 !important;
}
</style>